import { template as template_c1c0c682a1414ae5a9e928d59a1479f3 } from "@ember/template-compiler";
const WelcomeHeader = template_c1c0c682a1414ae5a9e928d59a1479f3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
