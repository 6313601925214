import { template as template_0eb18348313d477289c17266e66d650c } from "@ember/template-compiler";
const FKText = template_0eb18348313d477289c17266e66d650c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
