import { template as template_ea563021a8d34759b083a424a1f6f3dc } from "@ember/template-compiler";
const SidebarSectionMessage = template_ea563021a8d34759b083a424a1f6f3dc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
